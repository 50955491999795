
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        








































































@import '~codemirror/lib/codemirror.css';

.app-code-mirror {
  .CodeMirror {
    z-index: 0;
    height: auto;
    font-size: $--clb-font-size-xs;
    line-height: $--clb-font-size-base;
    border: 1px solid $--clb-border-color-base;
    border-radius: $--clb-border-radius;
    cursor: text;
    -webkit-user-select: auto;
    -khtml-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }

  &:not(.read-only) .CodeMirror {
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      border-color: $--clb-color-primary;
    }
  }

  .CodeMirror-scroll {
    max-height: 480px;
    min-height: 160px;
  }

  /* stylelint-disable */
  .cm-s-jumbleberry-light.CodeMirror {
    background: $--background-color-base;
    color: #202020;
  }
  .cm-s-jumbleberry-light div.CodeMirror-selected {
    background: $--clb-skeleton-color;
  }
  .cm-s-jumbleberry-light .CodeMirror-line::selection,
  .cm-s-jumbleberry-light .CodeMirror-line > span::selection,
  .cm-s-jumbleberry-light .CodeMirror-line > span > span::selection {
    background: #e3e7f8;
  }
  .cm-s-jumbleberry-light .CodeMirror-line::-moz-selection,
  .cm-s-jumbleberry-light .CodeMirror-line > span::-moz-selection,
  .cm-s-jumbleberry-light .CodeMirror-line > span > span::-moz-selection {
    background: #e3e7f8;
  }
  .cm-s-jumbleberry-light .CodeMirror-gutters {
    background: $--clb-color-secondary__light;
    border-right: 1px solid $--border-color-base;
    padding-right: 4px;
  }
  .cm-s-jumbleberry-light .CodeMirror-guttermarker {
    color: #22863a;
  }
  .cm-s-jumbleberry-light .CodeMirror-guttermarker-subtle {
    color: #b0b0b0;
  }
  .cm-s-jumbleberry-light .CodeMirror-linenumber {
    color: #b0b0b0;
  }
  .cm-s-jumbleberry-light .CodeMirror-cursor {
    border-left: 1px solid #505050;
  }
  .cm-s-jumbleberry-light span.cm-comment {
    color: #6a737d;
  }
  .cm-s-jumbleberry-light span.cm-atom {
    color: #032f62;
  }
  .cm-s-jumbleberry-light span.cm-number {
    color: #032f62;
  }
  .cm-s-jumbleberry-light span.cm-property,
  .cm-s-jumbleberry-light span.cm-attribute {
    color: #21243d;
  }
  .cm-s-jumbleberry-light span.cm-keyword {
    color: #22863a;
  }
  .cm-s-jumbleberry-light span.cm-qualifier {
    color: #6f42c1;
  }
  .cm-s-jumbleberry-light span.cm-string {
    color: #e28a0e;
  }
  .cm-s-jumbleberry-light span.cm-variable {
    color: #005cc5;
  }
  .cm-s-jumbleberry-light span.cm-variable-2 {
    color: #005cc5;
  }
  .cm-s-jumbleberry-light span.cm-def {
    color: #d73a49;
  }
  .cm-s-jumbleberry-light span.cm-bracket {
    color: #202020;
  }
  .cm-s-jumbleberry-light span.cm-tag {
    color: #22863a;
  }
  .cm-s-jumbleberry-light span.cm-link {
    color: #aa759f;
  }
  .cm-s-jumbleberry-light span.cm-error {
    background: $--clb-color-danger;
    color: #fff;
  }
  .cm-s-jumbleberry-light .CodeMirror-activeline-background {
    background: #c1c1c1;
  }
  .cm-s-jumbleberry-light .CodeMirror-matchingbracket {
    color: #f5f5f5 !important;
    background-color: $--clb-color-primary !important;
  }
  /* stylelint-enable */
}
